import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d77759a4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");
  return $props.action ? (_openBlock(), _createBlock($setup["ActionBase"], {
    key: 0,
    title: "云台参数",
    id: $props.action.id
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["HeaderBlock"], {
        title: "云台俯仰角",
        "class": "block"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_slider, {
            style: {
              "padding": "0 1.5rem"
            },
            modelValue: $setup.data.gimbalPitchRotateAngle,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.data.gimbalPitchRotateAngle = $event;
            }),
            min: $setup.pitch.min,
            max: $setup.pitch.max
          }, null, 8 /* PROPS */, ["modelValue", "min", "max"]), _createVNode($setup["InputLabel"], {
            min: $setup.pitch.min,
            max: $setup.pitch.max,
            modelValue: $setup.data.gimbalPitchRotateAngle,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.data.gimbalPitchRotateAngle = $event;
            }),
            unit: "°"
          }, null, 8 /* PROPS */, ["min", "max", "modelValue"])])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"])) : _createCommentVNode("v-if", true);
}