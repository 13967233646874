import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");
  return $props.action ? (_openBlock(), _createBlock($setup["ActionBase"], {
    key: 0,
    title: "飞行器偏航角",
    id: $props.action.id
  }, {
    middle: _withCtx(function () {
      return [_createVNode($setup["InputLabel"], {
        min: -180,
        max: 180,
        modelValue: $setup.data.aircraftHeading,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.data.aircraftHeading = $event;
        }),
        unit: "°"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    "default": _withCtx(function () {
      return [_createVNode(_component_el_slider, {
        style: {
          "padding": "0 1rem"
        },
        modelValue: $setup.data.aircraftHeading,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.data.aircraftHeading = $event;
        }),
        min: -180,
        max: 180
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"])) : _createCommentVNode("v-if", true);
}