import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");
  return $props.action ? (_openBlock(), _createBlock($setup["ActionBase"], {
    key: 0,
    id: $props.action.id,
    title: "相机变焦"
  }, {
    middle: _withCtx(function () {
      return [_createVNode($setup["InputLabel"], {
        min: $setup.zoomRange.min,
        max: $setup.zoomRange.max,
        modelValue: $setup.data.focalLength,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.data.focalLength = $event;
        }),
        unit: "X"
      }, null, 8 /* PROPS */, ["min", "max", "modelValue"])];
    }),
    "default": _withCtx(function () {
      return [_createVNode(_component_el_slider, {
        style: {
          "padding": "0 1.5rem"
        },
        min: $setup.zoomRange.min,
        max: $setup.zoomRange.max,
        modelValue: $setup.data.focalLength,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.data.focalLength = $event;
        })
      }, null, 8 /* PROPS */, ["min", "max", "modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"])) : _createCommentVNode("v-if", true);
}