import { parseWaylineInfo } from '@/api/api.wayline'
import NotifyException from '@/common/errors/NotifyException'
import DataException from '@/common/errors/DataException'
import { MAP_COORDINATE } from '@/common/global/JlinkValues'
import JlinkRouter from '@/common/global/JlinkRouter'
import WaylineBigemapHelper from '@/common/helper/map/WaylineBigemapHelper'
import { ActionType } from '@/app/views/pages/library/wayline/WaylineValuesEnum'
import WaypointBigemapHelper from '@/common/helper/map/WaypointBigemapHelper'
import { Ref } from 'vue'
import Mapping2dBigemapHelper from '@/common/helper/map/Mapping2dBigemapHelper'
import { mittError } from '@/common/mitt/mitt'
import CesiumService from '@/components/cesium/CesiumService'

export default class WaylineLoader {
  static baseInfo?: TemplateBaseInfo = undefined
  static waylineData?:TemplateWaypointInfo|TemplateMapping2DInfo = undefined
  static mapHelper?:WaylineBigemapHelper<TemplateWaypointInfo|TemplateMapping2DInfo> = undefined
  /* 新建航线 */
  static async makeNewWayline (data: TemplateBaseInfo) {
    this.baseInfo = data
    this.waylineData = this.createData(undefined)
    await JlinkRouter.goPageWaylineEditor()
  }

  // 编辑航线
  static async editWayline (waylineId: string) {
    const data = await parseWaylineInfo({ waylineId, coordinate: MAP_COORDINATE })
    if (data.common.waylineId) {
      this.baseInfo = data.common
      this.waylineData = this.createData(data)
      await JlinkRouter.goPageWaylineEditor()
    } else {
      mittError(new NotifyException('数据错误,返回的网络数据不包含 waylineId', 'error'))
    }
  }

  static reset (): void {
    this.mapHelper?.destroy()
    delete this.baseInfo
    delete this.waylineData
    delete this.mapHelper
  }

  static createData (data?:TemplateWaylineInfo) {
    if (this.baseInfo) {
      if (this.baseInfo.templateType === 'waypoint') {
        let waylinePoint:TemplateWaypointInfo
        if (data) {
          waylinePoint = data as TemplateWaypointInfo
        } else {
          waylinePoint = {
            common: {
              waylineId: '',
              ...this.baseInfo,
              distance: 0,
              estimatedTime: 0,
              flyToWaylineMode: 'safely',
              takeOffSecurityHeight: 30,
              heightMode: 'relativeToStartPoint',
              globalTransitionalSpeed: 15,
              globalPayloadLens: ['zoom', 'wide', 'ir'],
              autoFlightSpeed: 10,
              photoNum: 0,
              coordinate: MAP_COORDINATE
            },
            globalWaypointHeadingMode: 'followWayline',
            globalWaypointTurnMode: 'coordinateTurn',
            globalWaypointHeadingPathMode: 'followBadArc',
            height: 150,
            ellipsoidHeight: 150,
            points: []
          } as TemplateWaypointInfo
        }
        return waylinePoint
      } else if (this.baseInfo.templateType === 'mapping2d') {
        let wayline2D:TemplateMapping2DInfo
        if (data) {
          wayline2D = data as TemplateMapping2DInfo
        } else {
          wayline2D = {
            common: {
              shootSurfaceHeight: 0,
              waylineId: '',
              ...this.baseInfo,
              takeOffSecurityHeight: 30,
              autoFlightSpeed: 10,
              flyToWaylineMode: 'safely',
              heightMode: 'EGM96',
              globalPayloadLens: ['visable'],
              globalTransitionalSpeed: 15,
              distance: 0,
              estimatedTime: 0,
              polygonSquare: 0,
              photoNum: 0,
              coordinate: MAP_COORDINATE,
              wideGSD: 5,
              globalShootHeight: 150,
            },
            height: 150,
            ellipsoidHeight: 150,
            orthoCameraOverlapH: 80,
            orthoCameraOverlapW: 70,
            elevationOptimizeEnable: 1,
            shootType: 'time',
            direction: 0,
            margin: 0,
            actionTriggerParam: 0,
            points: [],
            polygon: [],

          }
        }
        return wayline2D
      } else {
        throw new NotifyException('当前航线类型航线暂不支持', 'info')
      }
    } else {
      throw new NotifyException('请线初始化航线数据再创建地图辅助', 'info')
    }
  }

  static createMapHelper<T extends WaylineBigemapHelper<any>> (bigemapRef: Ref<{ mapService: () => Promise<CesiumService> | CesiumService } | undefined>) {
    if (this.waylineData) {
      if (this.waylineData.common.templateType === 'waypoint') {
        this.mapHelper = new WaypointBigemapHelper(this.waylineData as TemplateWaypointInfo, bigemapRef)
      } else if (this.waylineData.common.templateType === 'mapping2d') {
        this.mapHelper = new Mapping2dBigemapHelper(this.waylineData as TemplateMapping2DInfo, bigemapRef)
      } else {
        throw new DataException(`templateType ${this.waylineData.common.templateType} is not support now`)
      }
    } else {
      throw new DataException('waylineData is lost')
    }
    return this.mapHelper as T
  }

  static readonly getTemplateTypeValues = [
    {
      id: 0,
      title: '航点航线',
      name: 'route-0',
      type: 'waypoint',
      support: true
    },
    {
      id: 1,
      title: '建图航拍',
      type: 'mapping2d',
      name: 'route-1',
      support: true,
      checkFlag: false
    },
    {
      id: 2,
      title: '倾斜摄影',
      type: 'mapping3d',
      name: 'route-2',
      support: false

    },
    {
      id: 3,
      title: '带状航线',
      type: 'mappingStrip',
      name: 'route-3',
      support: false
    }
  ]

  static readonly getHeightModeValues = [
    // {
    //   value: 'mappingStrip',
    //   label: '绝对高度'
    // },
    {
      value: 'relativeToStartPoint',
      label: '相对起飞点高度'
    },
    {
      value: 'aboveGroundLevel',
      label: '相对地面高度'
    },
    {
      value: 'EGM96',
      label: '海拔高度'
    }, {
      value: 'realTimeFollowSurface',
      label: '实时仿地'

    }
  ]

  static readonly getWaypointHeadingMode :{key:WaypointHeadingMode, label:string}[] = [
    {
      key: 'followWayline',
      label: '沿航线方向',
    },
    {
      key: 'fixed',
      label: '锁定当前偏航角',
    },
    {
      key: 'manually',
      label: '手动控制',
    },
    {
      key: 'smoothTransition',
      label: '均匀过渡至下一个航点',
    },
    {
      key: 'towardPOI',
      label: '朝向兴趣点',
    },
  ]

  static readonly getWaypointTurnMode = [
    {
      key: 'coordinateTurn',
      label: '协调转弯，不过点，提前转弯',
      icon: 'coordinateTurn'
    },
    {
      key: 'toPointAndStopWithDiscontinuityCurvature',
      label: '直线飞行，飞行器到点停',
      icon: 'toPointAndStopWithDiscontinuityCurvature'
    },
    {
      key: 'toPointAndStopWithContinuityCurvature',
      label: '曲线飞行，飞行器到点停',
      icon: 'toPointAndStopWithContinuityCurvature'
    },
    {
      key: 'toPointAndPassWithContinuityCurvature',
      label: '曲线飞行，飞行器过点不停',
      icon: 'toPointAndPassWithContinuityCurvature'
    },
  ]

  static readonly getMappingTakePhotoMode = [
    {
      key: 'time',
      label: '等时间隔拍照',
      icon: 'coordinateTurn'
    },
    {
      key: 'distance',
      label: '等距间隔拍照',
      icon: 'distance'
    }
  ]
}
