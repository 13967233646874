import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7a834bd4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "icons"
};
var _hoisted_2 = {
  "class": "lnglat"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock($setup["HeaderBlock"], {
    title: "飞行器偏航角模式"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_select, {
        size: "large",
        modelValue: $setup.waypointHeadingMode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.waypointHeadingMode = $event;
        })
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.waypointHeadingModeList, function (option) {
            return _openBlock(), _createBlock(_component_el_option, {
              key: option.key,
              value: option.key,
              label: option.label
            }, null, 8 /* PROPS */, ["value", "label"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]), $setup.currentPointNext && $setup.waypointHeadingMode === 'smoothTransition' ? (_openBlock(), _createBlock($setup["ActionBase"], {
        key: 0,
        title: "下一个航点飞行器偏航角",
        style: {
          "margin-top": ".5rem"
        }
      }, {
        middle: _withCtx(function () {
          return [$setup.currentPointNext ? (_openBlock(), _createBlock($setup["InputLabel"], {
            key: 0,
            min: -180,
            max: 180,
            modelValue: $setup.currentPointNext.waypointHeadingAngle,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.currentPointNext.waypointHeadingAngle = $event;
            }),
            unit: "°",
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $setup.waypointHeadingMode === 'towardPOI' ? (_openBlock(), _createBlock($setup["ActionBase"], {
        key: 1,
        title: "飞行器兴趣点",
        style: {
          "margin-top": ".5rem"
        },
        "class": "point-toward"
      }, {
        middle: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "点击右键拾取兴趣点"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "svg-notice"
              })];
            }),
            _: 1 /* STABLE */
          })])];
        }),
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createTextVNode(" LNG "), _createVNode($setup["InputLabel"], {
            disabled: "",
            min: 72,
            max: 138,
            modelValue: $setup.waypointPoiPoint.lng,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.waypointPoiPoint.lng = $event;
            }),
            unit: "°",
            style: {
              "margin": ".5rem 0",
              "width": "100%"
            },
            flex: ""
          }, null, 8 /* PROPS */, ["modelValue"]), _createTextVNode(" LAT "), _createVNode($setup["InputLabel"], {
            disabled: "",
            min: .8,
            max: 56,
            modelValue: $setup.waypointPoiPoint.lat,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $setup.waypointPoiPoint.lat = $event;
            }),
            unit: "°",
            style: {
              "margin-top": ".5rem",
              "width": "100%"
            },
            flex: ""
          }, null, 8 /* PROPS */, ["modelValue"])])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $setup.waypointHeadingMode === 'manually' ? (_openBlock(), _createBlock($setup["ActionBase"], {
        key: 2,
        title: "手动控制",
        style: {
          "margin-top": ".5rem"
        },
        "class": "point-manually"
      }, {
        middle: _withCtx(function () {
          return [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "飞行器在飞至下一航点的过程中，用户可以手动控制飞行器机头朝向"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "svg-notice"
              })];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $setup.waypointHeadingMode === 'followWayline' ? (_openBlock(), _createBlock($setup["ActionBase"], {
        key: 3,
        title: "沿航线方向",
        style: {
          "margin-top": ".5rem"
        },
        "class": "point-manually"
      }, {
        middle: _withCtx(function () {
          return [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "飞行器机头沿着航线方向飞至下一航点"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "svg-notice"
              })];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $setup.waypointHeadingMode === 'fixed' ? (_openBlock(), _createBlock($setup["ActionBase"], {
        key: 4,
        title: "锁定偏航角",
        style: {
          "margin-top": ".5rem"
        },
        "class": "point-manually"
      }, {
        middle: _withCtx(function () {
          return [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "飞行器机头保持执行完航点动作后的飞行器偏航角飞至下一航点"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "svg-notice"
              })];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}