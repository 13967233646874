import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode("    飞行器偏航角模式        "), _createVNode($setup["HeaderBlock"], {
    title: "拍照模式"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_select, {
        modelValue: $setup.editValue.shootType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.editValue.shootType = $event;
        }),
        placeholder: "Select"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.takeModeList, function (item) {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.key,
              label: item.label,
              value: item.key,
              "class": "turnList"
            }, {
              "default": _withCtx(function () {
                return [_createElementVNode("span", null, _toDisplayString(item.label), 1 /* TEXT */)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  })], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}