import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0a2d7124"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "action-item"
};
var _hoisted_2 = ["onDragenter", "onDragstart"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock($setup["HeaderBlock"], {
    title: "添加航点动作"
  }, {
    wrapper: _withCtx(function () {
      return [_createVNode(_component_el_tooltip, {
        placement: "top",
        content: "添加动作"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepSvgIcon"], {
            name: "plus",
            "class": "icon",
            onClick: $setup.toggle
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          onDragenter: function onDragenter($event) {
            return $setup.dragenter($event, index);
          },
          onDragover: $setup.dragover,
          onDragstart: function onDragstart($event) {
            return $setup.dragstart(index);
          },
          draggable: "true"
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.getComponents(item.actionType)), {
          action: item
        }, null, 8 /* PROPS */, ["action"]))], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  });
}