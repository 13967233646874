import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b38933cc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "direction-vernier"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    "class": "vernier",
    style: _normalizeStyle({
      rotate: $setup.direction + 'deg'
    })
  }, null, 4 /* STYLE */)]);
}