import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-550dea51"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "altitude-flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DeepSvgIcon = _resolveComponent("DeepSvgIcon");
  return $props.action ? (_openBlock(), _createBlock($setup["ActionBase"], {
    key: 0,
    title: "悬停",
    id: $props.action.id
  }, {
    middle: _withCtx(function () {
      return [_createVNode($setup["InputLabel"], {
        min: 1,
        max: 9999999,
        modelValue: $setup.data.hoverTime,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.data.hoverTime = $event;
        }),
        unit: "s"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        "class": "addtion",
        onClick: $setup.changeDown
      }, [_createVNode(_component_DeepSvgIcon, {
        name: "subtract",
        "class": "addtion-icon"
      })]), _withDirectives(_createElementVNode("input", {
        "class": "input input-addtion",
        type: "number",
        onClick: $setup.inputClick,
        onChange: $setup.changeInput,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.hoverCostStep = $event;
        })
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [[_vModelText, $setup.hoverCostStep]]), _createElementVNode("div", {
        "class": "addtion",
        onClick: $setup.changeUp
      }, [_createVNode(_component_DeepSvgIcon, {
        name: "plus",
        "class": "addtion-icon"
      })])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"])) : _createCommentVNode("v-if", true);
}