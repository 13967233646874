import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_switch = _resolveComponent("el-switch");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode($setup["HeaderBlock"], {
    title: "高程优化"
  }, {
    wrapper: _withCtx(function () {
      return [_createVNode(_component_el_switch, {
        modelValue: $setup.editValue.elevationOptimizeEnable,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.editValue.elevationOptimizeEnable = $event;
        }),
        "active-value": 1,
        "inactive-value": 0,
        "active-color": "#3C4DA5",
        "inactive-color": "#CBCBCB"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    middle: _withCtx(function () {
      return [_createVNode(_component_el_tooltip, {
        placement: "right",
        content: "开启后，飞机会在航线飞行完毕后，飞向测区中心采集一组倾斜照片，最终模型将有更准确的高度信息"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepSvgIcon"], {
            name: "svg-notice",
            "class": "icon"
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}