import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  style: {
    "display": "flex",
    "justify-content": "space-between"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  var _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return $props.action ? (_openBlock(), _createBlock($setup["ActionBase"], {
    key: 0,
    title: "停止录像",
    id: $props.action.id
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_checkbox_group, {
        modelValue: $setup.data.payloadLensIndex,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.data.payloadLensIndex = $event;
        }),
        min: 1,
        max: $setup.chooseOptions.length
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chooseOptions, function (options) {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              value: options.key,
              key: options.key
            }, {
              "default": _withCtx(function () {
                return [_createTextVNode(_toDisplayString(options.label), 1 /* TEXT */)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "max"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"])) : _createCommentVNode("v-if", true);
}