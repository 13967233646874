import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "padding": "0 0.7rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");
  return _openBlock(), _createElementBlock("div", null, [$setup.editValue.common.globalPayloadLens.includes('wide') || $setup.editValue.common.globalPayloadLens.includes('visable') ? (_openBlock(), _createBlock($setup["HeaderBlock"], {
    key: 0,
    title: $setup.editValue.common.globalPayloadLens.includes('wide') ? 'GSD(广角)' : 'GSD(可见光)'
  }, {
    wrapper: _withCtx(function () {
      return [_createVNode($setup["InputLabel"], {
        min: $setup.wideGsdRange.min,
        max: $setup.wideGsdRange.max,
        modelValue: $setup.editValue.common.wideGSD,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.editValue.common.wideGSD = $event;
        }),
        unit: "cm/pixel"
      }, null, 8 /* PROPS */, ["min", "max", "modelValue"])];
    }),
    "default": _withCtx(function () {
      return [_createVNode(_component_el_slider, {
        min: $setup.wideGsdRange.min,
        max: $setup.wideGsdRange.max,
        modelValue: $setup.editValue.common.wideGSD,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.editValue.common.wideGSD = $event;
        }),
        step: .1,
        style: {
          "padding": "0 .5rem"
        }
      }, null, 8 /* PROPS */, ["min", "max", "modelValue"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString($setup.wideGsdRange.min), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString($setup.wideGsdRange.max), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createCommentVNode("      <HeaderBlock title=\"GSD(红外)\" v-if=\"editValue.globalPayloadLens.includes('ir')\">"), _createCommentVNode("        <template #wrapper>"), _createCommentVNode("          <InputLabel :min=\"irGsdRange.min\" :max=\"irGsdRange.max\" v-model=\"editValue.irGSD\" unit=\"cm/pixel\"></InputLabel>"), _createCommentVNode("        </template>"), _createCommentVNode("        <el-slider :min=\"irGsdRange.min\" :max=\"irGsdRange.max\" v-model=\"editValue.irGSD\" step=\".1\" style=\"padding: 0 .5rem\"></el-slider>"), _createCommentVNode("        <div style=\"display: flex;justify-content: space-between;padding: 0 0.7rem\">"), _createCommentVNode("          <span>{{ irGsdRange.min }}</span>"), _createCommentVNode("          <span>{{ irGsdRange.max }}</span>"), _createCommentVNode("        </div>"), _createCommentVNode("      </HeaderBlock>")]);
}