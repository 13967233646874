import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-41475a38"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "action-base"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "item-top-let"
};
var _hoisted_4 = {
  "class": "title"
};
var _hoisted_5 = {
  key: 0,
  "class": "middle"
};
var _hoisted_6 = {
  key: 0,
  "class": "body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepSvgIcon"], {
    name: "list-icon",
    "class": "icon"
  }), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.props.title), 1 /* TEXT */)]), _ctx.$slots.middle ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "middle", {}, undefined, true)])) : _createCommentVNode("v-if", true), _ctx.$props.id ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
    key: 1,
    name: "garbage",
    "class": "icon-garbage",
    onClick: $setup.deleteAction
  })) : _createCommentVNode("v-if", true)]), _ctx.$slots["default"] ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("v-if", true)]);
}