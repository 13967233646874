import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");
  return _openBlock(), _createBlock($setup["HeaderBlock"], _mergeProps({
    title: $props.title
  }, _ctx.$attrs), {
    wrapper: _withCtx(function () {
      return [_createVNode($setup["InputLabel"], {
        min: $setup.min,
        max: $setup.max,
        modelValue: $setup.overlap,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.overlap = $event;
        }),
        unit: "%"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    "default": _withCtx(function () {
      return [_createVNode(_component_el_slider, {
        min: $setup.min,
        max: $setup.max,
        modelValue: $setup.overlap,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.overlap = $event;
        }),
        style: {
          "padding": "0 .5rem"
        }
      }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("div", {
        style: {
          "display": "flex",
          "justify-content": "space-between",
          "padding": "0 0.7rem"
        }
      }, [_createElementVNode("span", null, _toDisplayString($setup.min)), _createElementVNode("span", null, _toDisplayString($setup.max))])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["title"]);
}